:root {
  --bg-gr12: #e7e6e6;
}
.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading .ant-spin {
  color: #3e79f7;
}
/* start feez content */
.print-history b {
  text-align: center;
  display: block;
  position: 'absolute';
  left: '0px';
}
.feesPrint {
  display: grid;
  grid-template-columns: auto 1fr auto;
  padding: 0px 10px;
  color: #000;
  font-size: 23px;
  width: 100%;
  border: 1px solid transparent;
  border: 1px solid transparent;
  align-items: center;
}
.feesPrint,
.check-box {
  position: relative;
  bottom: 30px;
  right: 20px;
}
.feesPrint :nth-last-of-type(1) {
  direction: ltr;
}
.pay-desc {
  position: relative;
  top: 15px;
}
.fees-grade {
  position: relative;
  top: 7px;
}
.paee_name {
  border-bottom: 1px solid transparent;
  border-bottom: 1px solid transparent;
  font-weight: bold;
  font-size: 23px;
  position: relative;
  top: 3px;
  right: 160px;
}
.tablez b {
  font-size: 20px;
  color: #000;
}
.tablez {
  text-align: end;
}
.tablez b span {
  font-size: 20px;
}
.check-box {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 2fr 2fr;
  width: 100%;
  border: 1px solid transparent;
  border: 1px solid transparent;
  border-radius: 5px;
  padding: 0px 10px;
  font-size: 23px;
  color: #000;
  align-items: center;
  margin-top: 0px;
  top: -7px;
}
.check-box > div {
  overflow: hidden;
}
.check-box > div:nth-child(1) {
  position: relative;
  right: 180px;
}
.check-box > div:nth-child(2) {
  position: relative;
  right: 133px;
}
.check-box > div:nth-child(3) {
  position: relative;
  right: 80px;
}
.check-box > div:nth-child(4) {
  position: relative;
  right: 65px;
}
.check-box > div:nth-child(5) {
  position: relative;
  right: 20px;
}

.feesPrint b:nth-child(1),
.feesPrint b:nth-child(3) {
  font-size: 25px;
}
.box {
  width: 30px;
  height: 30px;
  display: inline-block;
  margin-left: 5px;
  border: 1px solid transparent;
  border: 1px solid transparent;
  margin-bottom: -8px;
  right: 45px;
}
.box svg {
  display: block;
  font-size: smaller;
}
.bank-name {
  border-bottom: 1px solid transparent;
  border-bottom: 1px solid transparent;
  font-weight: 900;
  margin: 0px 5px;
  min-width: 150px;
  display: inline-block;
  position: relative;
  bottom: 5px;
  right: 45px;
}
.check-name {
  font-weight: 900;
  margin: 0px 5px;
  /* right: 65px; */
  position: relative;
  bottom: 5px;
}
.FeesPrint.attendancePrint {
  height: auto;
}
.receive-sign {
  display: flex;
  justify-content: space-between;
}
.sign {
  align-self: flex-end;
  font-size: 30px;
  color: black;
  min-width: 300px;
}
.receiver {
  display: inline-flex;
  height: 100px;
  border-bottom: 1px solid transparent;
  border-bottom: 1px solid transparent;
  color: #000;
  font-size: 22px;
  position: relative;
  bottom: 20px;
  right: 210px;
}
.receiver div:nth-last-of-type(2) {
  align-self: flex-end;
}
.receip-print {
  font-family: Roboto, RobotoDraft, Helvetica, Arial, sans-serif;
}
.FeesPrint .boxView b {
  font-weight: 900;
  font-size: 25px !important;
}
.sanad {
  color: #000;
  font-size: 22px;
  padding: 3px 72px;
  border: 1px solid transparent;
  border: 1px solid transparent;
  font-weight: 900;
}
.date {
  color: #000;
  font-size: 20px;
  margin-bottom: 10px;
  border: 1px solid transparent;
  border: 1px solid transparent;
  font-weight: 900;
  font-size: 25px;
  padding: 5px;
  display: block;
  position: relative;
  right: 50px;
  right: 220px;
}
.date:nth-child(1) {
  top: 3px;
}
.date:nth-child(2) {
  bottom: 17px;
}
.date span {
  display: inline-block;
}
.paid-amount {
  font-size: 23px !important;
  position: relative;
  top: 13px;
  right: 140px;
}
.pay-desc-relative {
  position: relative;
  top: 30px;
}
.tablez table {
  border: 1px solid transparent;
  border: 1px solid transparent;
  border-collapse: collapse;
}
.tablez table tr,
.tablez table td,
.tablez table th {
  border: 1px solid transparent;
  border: 1px solid transparent;
}
.tablez table b strong {
  font-weight: 900;
  font-size: 25px;
}
.tablez td {
  position: relative;
  right: 5px;
  bottom: 2px;
}
.tablez td:nth-child(1) {
  right: -13px;
}
.footer-container-ar {
  width: 49%;
  max-width: 300px;
}
.footer-container-ar p {
  text-align: right !important;
  font-size: 15px !important;
  line-height: 19px;
}
.footer-container-en {
  width: 49%;
  max-width: 300px;
}
.footer-container-en p {
  text-align: left !important;
}
.header-ar,
.header-en {
  width: 40%;
  max-width: 272px;
}

.header-ar p,
.header-en p {
  font-size: 20px;
  font-weight: 900 !important;
}
.receip-no {
  font-size: 25px;
  font-weight: 900;
  position: relative;
  bottom: 11px;
  right: 100px;
}
/* end feez content */
/* start printing short report  */
.printing-container {
  font-family: 'Sakkal Majalla' !important;
  font-style: normal;
  text-align: center;
  color: #000;
  font-size: 24px;
  min-width: 1016px;
  padding: 15px;
  margin: auto;
  height: 1500px;
  display: grid;
  grid-template-rows: auto 1fr auto;
}
.text-printing {
  color: #000;
}
.header-table {
  color: #000;
}
.header > img {
  width: 200px;
}
.header > h3 {
  margin-top: 20px;
  color: #000;
  font-weight: 900;
  margin-bottom: 25px;
}
.header > h3 > span {
  border-bottom: 2px solid #000;
  line-height: 24px;
  display: inline-block;
  font-size: 30px;
}
.student-info {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 20px;
  width: 90%;
  margin: auto;
  font-size: 30px;
}
.class-student {
  text-align: start;
}
.stage {
  margin-left: 68px;
  margin-right: -50px;
}
.student-info h4 {
  color: hsl(0, 0%, 0%);
}
.name-lang {
  text-align: center;
  margin-right: -87px;
}
.name-lang div {
  margin-right: -117px;
}
.student-info article:nth-child(1) {
  margin-bottom: 15px;
}
.student-info .date-student-info {
  margin-top: 12px;
}
.name-lang h3 {
  margin: 0px 40px !important;
  color: #000;
  font-weight: 900;
  font-size: inherit;
}

.tables table {
  border: 1px solid #000;
  border-collapse: collapse;
  width: 98%;
  margin: 0px auto 5px auto;
}
.tables table td {
  border: 1px solid #000;
  text-align: center;
  vertical-align: middle;
  word-wrap: break-word;
  max-width: 60px;
}
.tables table tr {
  border: 1px solid #000;
  text-align: center;
  vertical-align: middle;
  margin-top: 5px;
}
table.fixed {
  table-layout: fixed;
}
.printing-footer {
  color: #000 !important;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  justify-content: space-between;
}
.printing-footer p {
  font-size: 20px !important;
  color: #000 !important;
  line-height: 20px;
}
.printing-footer img {
  margin-top: -20px;
}
.printing-footer .logoView {
  width: 200px;
}
.printing-footer div:nth-of-type(1) {
  text-align: left;
}
.printing-footer div:nth-last-of-type(1) {
  text-align: right;
}
.loadin-prin {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.not-transparenr {
  color: transparent;
  border: none !important;
}
.teacher-sign {
  width: 80%;
  margin: 50px auto 50px auto;
}
.teacher-sign article {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
}
.teacher-sign article div span {
  display: inline-block;
  border-bottom: 3px solid #000;
  line-height: 21px;
  min-width: 180px;
}
.print {
  background-color: green;
}
/* end printing short report  */

/* student stat */
.studentStatContainer {
  font-family: 'Sakkal Majalla' !important;
  font-style: normal;
  text-align: center;
  color: #000;
  font-size: 24px;
  padding: 15px;
  height: 700px;
}
.classStat {
  height: 1000px;
  width: 95%;
  margin: auto;
}

.studentStatContainer .stat-header {
  color: #000;
  font-size: 37px;
}
.studentStatContainer table {
  border: 4px solid #000;
  border-collapse: collapse;
  width: 100%;
  margin: 0px auto 5px auto;
}
.classStat table {
  border: 1px solid hsl(0, 0%, 0%);
  background-color: #b7cbe3;
}

.studentStatContainer table td {
  border: 1px solid #000;
  border-left: 4px solid #000;
  text-align: center;
  vertical-align: middle;
  word-wrap: break-word;
  max-width: 60px;
}
.classStat table td {
  border: 1px solid #000;
}
.studentStatContainer table tr,
.studentStatContainer table th {
  border: 1px solid #000;
  border-left: 4px solid #000;
  text-align: center;
  vertical-align: middle;
  margin-top: 5px;
}
.classStat table tr,
.classStat table th {
  border: 1px solid #000;
}
.studentStatContainer .student-stats-date {
  text-align: right;
  /* font-family: Roboto, RobotoDraft, Helvetica, Arial, sans-serif; */
  color: #000;
  font-weight: initial;
}
.studentStatContainer .student-stats-date .ar-date {
  font-family: 'Sakkal Majalla' !important;
  font-size: x-large;
  margin-left: 10px;
}
.studentStatContainer .student-stats-date > span {
  display: inline-block;
  margin-left: 10px;
}

.studentStatContainer .student-stats-date .stat-date {
  margin-left: 22px;
}

.studentStatContainer table tbody,
.studentStatContainer table thead {
  line-height: 1;
}

.border-left-none {
  border-left: none !important;
}
.border-top-none {
  border-top: none !important;
}
.border-bottom-none {
  border-bottom: none !important;
}
.border-right-none {
  border-right: none !important;
}

.border-4px {
  border-left: 4px solid #000;
}
.AM-BM {
  margin-left: 5px;
}
.stat-header-style {
  margin-top: 50px;
}
.stat-footer-style {
  margin-top: 20px;
}
/* end student stat */
/* start medical requests  */
.request-container {
  font-family: 'Sakkal Majalla' !important;
  font-style: normal;
  color: #000;
  padding: 15px;
  margin: auto;
  font-size: 24px;
}

.request-container div,
.request-container p {
  color: inherit;
}
.request-container p {
  margin-bottom: 3px !important;
}

.request-title {
  color: #000;
  text-align: center;
  border: 1px solid #000;
  border-radius: 5px;
  max-width: 250px;
  margin: 20px auto;
  font-family: 'Sakkal Majalla' !important;
  font-size: 22px;
  font-weight: 900;
}
.request-container .request-content {
  margin-left: 15px;
}
.school-managment {
  direction: ltr;
  color: #000;
  margin-left: 20px;
  font-size: 23px;
}
.request-sign {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.request-sign img {
  width: 150px;
}
.request-sign .sign-school-img {
  width: 150px;
}
.request-dash {
  margin-right: 150px;
}
.request-con {
  margin: 20px;
  border: 5px solid #000;
  background-color: white;
}

.request-footer {
  margin-bottom: 10px;
}
.request-header {
  margin-top: 20px;
}
.signImg {
  display: inline-block;
  height: 100px;
}
.sign {
  width: 150px;
}
.doctor-sign {
  display: flex;
  justify-content: space-between;
}

.bold {
  font-weight: 900;
  margin-right: 10px;
}
.form-control {
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.5em;
}
.paper-request {
  display: flex;
  align-items: center;
}
.paper-request p {
  width: 350px;
}
.paper-checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 100px;
}
.paper-checkbox svg {
  margin-left: 5px;
}

.request-notes {
  position: relative;
  display: inline-block;
  margin-bottom: 60px;
}
.request-notes::before {
  content: '';
  position: absolute;
  line-height: 0.8;
  width: 100%;
  height: 3px;
  display: inline-block;
  background-color: #000;
  bottom: 5px;
}
.papersign {
  display: flex;
  justify-content: space-between;
}
.paper-underline {
  display: inline-block;
  border-bottom: 3px solid #000;
  line-height: 18px;
  display: inline-block;
}
.sign-paper span {
  display: inline-block;
  width: 100px;
}
.request-header-document {
  display: flex;
  justify-content: space-between;
  direction: rtl;
  color: #000;
  width: 95%;
  margin: auto;
  font-family: 'Sakkal Majalla' !important;
  /* margin-top: 50px; */
}
.student-request-print {
  font-size: 20px;
}

.student-request {
  width: 98%;
  margin: auto;
  min-width: 1016px;
  border: 3px solid #000;
  background: white;
}
.request-header > section > article {
  border: 3px solid #000;
  margin-bottom: 10px;
  padding: 0 10px;
  text-align: center;
}

.request-student-table,
.request-student-table td,
.request-student-table tr,
.request-student-table th {
  border: 1px solid #000;
  border-collapse: collapse;
  text-align: center;
}
.request-student-table th {
  width: 50px;
}

.content-request-table,
.content-request-table td,
.content-request-table tr,
.content-request-table th {
  border: 1px solid #000;
  border-collapse: collapse;
  text-align: center;
}

.content-request-table {
  width: 95%;
  color: #000;
  font-size: 24px;
  font-family: 'Sakkal Majalla' !important;
  margin: 100px auto;
  direction: rtl;
}
.request-summary-content {
  color: #000;
  width: 95%;
  margin: auto;
  direction: rtl;
}
.request-summary-content table {
  width: 100%;
  color: #000;
  font-family: 'Sakkal Majalla' !important;
  text-align: center;
  font-size: 22px;
}
.request-summary-content p {
  color: #000;
  font-family: 'Sakkal Majalla' !important;
  text-align: center;
  font-size: 24px;
  height: auto !important;
  margin-bottom: 0px !important;
}
.printed-summary-page {
  height: 100%;
}
.table-one-student {
  color: #000;
  font-size: 18px;
  font-family: 'Sakkal Majalla' !important;
  text-align: center;
  width: 95%;
  margin: auto;
  /* margin-top: 50px; */
}
.table-one-student,
.table-one-student td,
.table-one-student tr,
.table-one-student th {
  border: 1px solid #000;
  border-collapse: collapse;
  text-align: center;
}
.auth-container {
  background: white;
}
.cl-red {
  color: red;
  direction: ltr;
}
.statstics-container {
  display: grid;
  grid-template-rows: auto 1fr auto;
  background: white;
  
}

/* end medical requests  */
/* start residence  */

.residence-container {
  margin: 5px 20px;
  direction: ltr;
}
.residence-container input,
.residence-container select {
  border: none;
  outline: none;
  line-height: 10px;
}
.residence-container * {
  color: #000;
  font-size: 16px;
  font-weight: 900;
  font-family: 'Sakkal Majalla' !important;
  text-align: center;
}
.residence-container select {
  text-align: center;
  font-size: 19px;
}
.residence-container input {
  font-size: 19px;
}

.residence-container .redience-header {
  display: grid;
  grid-template-columns: 150px 1fr 150px;
  justify-content: space-between;
  margin-bottom: 5px;
}
.residence-container .redience-header > article:nth-child(1) {
  text-align: center;
}
.residence-container .redience-header > article:nth-child(2) {
  margin: auto 10px;
  height: 140px;
}
.residence-container .redience-header > article:nth-child(3) {
  text-align: center;
}
.residence-container .redience-header > article img {
  width: 50%;
}
.residence-container .redience-header > article:nth-last-of-type(1) img {
  width: 60%;
}
.residence-container .redience-header article > p {
  font-size: 17px;
  font-weight: Bold;
  margin-bottom: 10px;
  line-height: 15px;
}
.residence-parent {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  text-align: center;
  border: 1px solid #000;
}

.residence-parent div {
  display: flex;
  height: 100%;
  align-items: center;
  width: 100%;
  justify-content: center;
  line-height: 15px;
}
.div1 {
  grid-area: 1 / 7 / 6 / 9;
  border-left: 1px solid #000;
  font-size: 35px;
  font-weight: 900;
  line-height: normal !important;
}
.div2 {
  grid-area: 1 / 1 / 2 / 7;
  border-bottom: 1px solid #000;
}
.div3 {
  grid-area: 2 / 6 / 4 / 7;
  border-bottom: 1px solid #000;
}
.div4 {
  grid-area: 2 / 5 / 4 / 6;
  border-bottom: 1px solid #000;
  border-right: 1px solid #000;
}
.div5 {
  grid-area: 2 / 4 / 4 / 5;
  border-bottom: 1px solid #000;
  border-right: 1px solid #000;
}
.div6 {
  grid-area: 2 / 3 / 4 / 4;
  border-bottom: 1px solid #000;
  border-right: 1px solid #000;
}
.div7 {
  grid-area: 2 / 2 / 4 / 3;
  border-bottom: 1px solid #000;
  border-right: 1px solid #000;
}
.div8 {
  grid-area: 2 / 1 / 4 / 2;
  border-bottom: 1px solid #000;
  border-right: 1px solid #000;
}
.div9 {
  grid-area: 4 / 5 / 5 / 7;
  border-bottom: 1px solid #000;
}
.div10 {
  grid-area: 4 / 3 / 5 / 5;
  border-bottom: 1px solid #000;
  border-right: 1px solid #000;
}
.div11 {
  grid-area: 4 / 1 / 5 / 3;
  border-bottom: 1px solid #000;
  border-right: 1px solid #000;
}
.div12 {
  grid-area: 5 / 1 / 6 / 7;
}

.residency-input {
  width: 100%;
  border: none;
  background-color: #fff;
  height: 100%;
}

.bg-gray-recidence {
  background-color: #bfbfbf;
}
.bg-sky-blue-residenece {
  background-color: #fff;
}
.residence-data-container {
  display: grid;
  grid-template-columns: 150px 1fr;
  border: 1px solid #000;
}
.residence-data-container .residence-data {
  border-left: 1px solid #000;
}
.residence-data-container .residence-data > div > section,
.employeer-data-container .residence-data > div > section,
.assingnor-data-container .residence-data > div > section {
  border-left: 1px solid #000;
}
.residence-data-container .residence-data > div > section:first-of-type,
.employeer-data-container .residence-data > div > section:first-of-type,
.assingnor-data-container .residence-data > div > section:first-of-type {
  border-left: none;
}
.residence-data-container .residence-data h1 p,
.employeer-data-container .residence-data h1 p,
.assingnor-data-container .residence-data h1 p,
.inquery-data-container .residence-data h1 p,
.friends-data-container .residence-data h1 p {
  font-weight: 900;
  margin-bottom: 0px;
  font-size: 20px;
  line-height: 25px;
}

.border-bottom-1 {
  border-bottom: 1px solid #000;
}
.border-left-1 {
  border-left: 1px solid #000;
}

.border-top-1 {
  border-top: 1px solid #000;
}
.border-right-1 {
  border-right: 1px solid #000;
}
.border-right-none {
  border-right: none !important;
}

.employeer-data-container {
  border: 1px solid #000;
  border-top: none;
}

.enrosment-data-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  border: 1px solid #000;
  border-top: 0px solid transparent;
}
.enrosment-data-container section:nth-child(1) {
  border-right: 1px solid #000;
}
.enrosment-data-container > section > p {
  font-weight: 900;
  text-align: center;
  margin-bottom: 0px;
}
.enrosment-data-container > section > ul {
  margin: 0 0px;
  padding: 0 20px;
}
.enrosment-data-container > section > ul > li {
  line-height: 20px;
}
.enrosment-data-container > section:nth-of-type(1) > ul > li {
  font-size: 17px;
  text-align: left;
  font-weight: 400;
}
.enrosment-data-container > section:nth-of-type(2) > ul > li {
  font-size: 19px;
  text-align: right;
  font-weight: 400;
}
.enrosment-data-container > section {
  display: grid;
  grid-template-rows: auto 1fr auto;
}
.enrosment-data-container > section > article {
  align-self: bottom;
}
.residence-sign {
  display: flex;
  width: 90%;
  margin: auto;
  justify-content: space-between;
  align-items: flex-end;
}

.assingnor-data-container {
  border: 1px solid #000;
  border-top: 0px solid transparent;
}
.assingnor-data-container * {
  text-align: center;
}

.border-left-1 {
  border-left: 1px solid #000;
}

.friends-data-container {
  border: 1px solid #000;
  margin-top: 50px;
}
.friends-data-container * {
  text-align: center;
}

.inquery-data-container {
  border: 1px solid #000;
  border-top: 0px solid transparent;
}

.inquery-data-container div p {
  font-size: 20px;
  font-weight: 900;
  margin-right: 10px;
  text-align: end;
  height: 300px;
}

.residenc-visa {
  background: #dadadb;
  font-size: 20px;
  font-weight: 900;
}

.reidendece-radio-btn[type='radio'] {
  /* remove standard background appearance */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* create custom radiobutton appearance */
  display: inline-block;
  width: 30px;
  height: 30px;
  padding: 6px;
  /* background-color only for content */
  background-clip: content-box;
  border: 2px solid #bbbbbb;
  background-color: #e7e6e7;
  border-radius: 50%;
}
/* appearance for checked radiobutton */
.reidendece-radio-btn[type='radio']:checked {
  background-color: #000;
  border: 3px solid #000;
}
@media print {
  .pagebreak {
    min-height: 1px;
    page-break-before: always;
  }
  html,
  body {
    background: white !important;
  }
  .padding-at-print{
    padding-top:15px ;
  }
}

/* end residence */
/* start system conversion */
.s-conversion-container {
  direction: ltr;
  margin: 10px;
  border: 2px solid #000;
  padding: 10px;
}
.s-conversion-container * {
  color: #000;
  font-size: 15px;
  font-family: 'Sakkal Majalla' !important;
  text-align: center;
}
.s-conversion-container input {
  font-weight: 900;
}
.s-conversion-header-container {
  display: grid;
  grid-template-columns: 1fr 150px;
  justify-content: space-between;
}
.s-conversion-header section img {
  width: 40%;
}
.s-conversion-capital {
  display: flex;
  justify-content: space-between;
}
.s-conversion-capital p {
  position: relative;
  line-height: 15px;
}
.s-conversion-capital p:nth-of-type(2)::before {
  display: inline-block;
  content: ' ';
  background-color: #000;
  width: 50px;
  height: 1px;
  position: absolute;
  right: 0px;
  bottom: 0px;
}

#city-select {
  /* -webkit-appearance: none;
    -moz-appearance: none; */
  appearance: none;
  text-indent: 1px;
  text-overflow: '';
  border-bottom: 1px solid #000;
  width: fit-content;
}

.s-conversion-capital p:nth-of-type(1)::before {
  width: 60px;
}
.s-conversion-capital p:nth-of-type(2)::before {
  width: 100%;
}
.border-1 {
  border: 1px solid #000;
}
.line-height-15 {
  line-height: 15px;
}
.inline-block {
  display: inline-block;
}
.text-end {
  text-align: end;
}
.text-start {
  text-align: start;
}
.text-center {
  text-align: center;
}

.s-history-order {
  position: relative;
}

.s-history-order::after {
  display: inline-block;
  content: ' ';
  background-color: #000;
  width: calc(50% - 100px);
  height: 1px;
  position: absolute;
  right: 100px;
  bottom: 0px;
}
.s-order-num {
  position: relative;
}
.s-order-num::after {
  display: inline-block;
  content: ' ';
  background-color: #000;
  width: calc(100% - 200px);
  height: 1px;
  position: absolute;
  right: 100px;
  bottom: 0px;
}
.s-employer-container > h1,
.s-employee-container > h1 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.s-employer-container > h1 > span,
.s-employee-container > h1 > span {
  display: inline-block;
  margin: 0 20px;
  color: red;
  font-weight: 900;
  min-width: 75px;
}
.s-employee-container input {
  width: 100%;
  border: 1px solid #000;
}
.s-employer-container > section {
  display: flex;
  justify-content: space-between;
}
.s-employer-container > section > article {
  width: 49%;
  border: 1px solid #000;
}

.s-employer-container
  > section
  > article:nth-of-type(2)
  .s-employer-input-container {
  display: grid;
  grid-template-columns: 1fr auto;
}

.s-employeer-input:focus {
  outline: none;
}
.s-employeer-input {
  width: 90%;
  border: none;
  border-bottom: 1px solid #000;
  height: 25px;
}

.s-disable-input {
  background: #e5e5e5;
}
.contract-header-container {
  display: grid;
  grid-template-columns: 1fr 114px;
  margin-top: 20px;
}
.contract-header-container > section > img {
  width: 60px;
  text-align: end;
}
.contract-header-container > section:nth-of-type(2) {
  display: grid;
  grid-template-rows: 1fr auto;
}
.header-section {
  transform: translateX(70px);
}

.contract-header-container > section:nth-of-type(2) h1 {
  font-size: 24px;
  font-weight: 900;
}
.contract-header-container > section:nth-of-type(1) h1 {
  font-size: 24px;
  font-weight: 900;
  line-height: 30px;
}

/* end system converdsion */
/* start contract */
.contract-content {
  display: grid;
  grid-template-columns: 1.1fr 1fr;
  border: 5px solid #000;
}
.contract-content > section:nth-of-type(2) {
  border: 1px solid #000;
  outline: 1px solid#fff;
  padding: 5px;
}
.contract-content > section:nth-of-type(1) {
  border: 1px solid #000;
  outline: 1px solid#fff;
  border-right: 5px solid #000;
  padding: 5px;
}
.contract-content > section > h1 {
  font-size: 23px;
  margin: 0px;
  font-weight: 900;
  display: inline-block;
  line-height: 20px;
}
.contract-content .contact-info-ar * {
  margin-bottom: 0;
  text-align: right;
  line-height: 18px;
}
.contract-content .contact-info-en * {
  margin-bottom: 0;
  text-align: left;
  line-height: 18px;
}
.contract-content .contact-info-ar {
  float: right;
}

.articel-container-en * {
  margin: 0;
  line-height: 18px;
  text-align: left;
  font-size: 14px;
}

.articel-container-ar * {
  clear: both;
  margin: 0;
  line-height: 18px;
  text-align: right;
  font-size: 14px;
}

.clr-red {
  color: red;
}
.contract-content .contact-sign {
  display: flex;
  justify-content: space-between;
  width: 43%;
  margin: auto;
}
.s-employee-container > section {
  border: 1px solid #000;
  padding: 5px;
}
.s-newjob input {
  border: 1px solid #000;
}
.s-footer-sign {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: auto;
}
.s-footer-sign > p {
  font-size: 20px;
  font-weight: 900;
}
.ant-table-wrapper.ant-table-wrapper-rtl.ltr {
  direction: ltr !important;
}
.ant-table-wrapper.ant-table-wrapper-rtl.ltr .ant-table.ant-table-rtl {
  direction: ltr !important;
}
#centered-input {
  text-align: center;
  padding: 0px !important;
}
/* end contract */

/* start certification pdf */
.gr34-end-header-container {
  direction: rtl;
  color: #000;
  display: flex;
  align-items: end;
  text-align: center;
  font-family: 'sakkal-majalla';
  justify-content: center;
  margin-bottom: 5px;
}
.gr34-end-header-container > p {
  position: absolute;
  right: 0px;
  margin-bottom: 0px;
}
.gr34-end-header-container > img {
  width: 50px;
}

.gr34-cer-container * {
  color: #000;
  font-size: 13px;
  font-family: 'Times New Roman D';
  direction: ltr;
}

.middle-cer-container * {
  color: #000;
  font-size: 12px;
  font-family: 'Times New Roman D';
  direction: ltr;
}
.high-cer-container * {
  color: #000;
  font-size: 14.5px;
  font-family: 'Times New Roman D';
  direction: ltr;
  text-align: center;
}
.font-serif {
  font-family: 'serif';
  font-size: 12px;
}
.font-majalla {
  font-family: 'sakkal-majalla' !important;
  font-size: 17px;
}
.middle-cer-container {
  margin: 5px 40px 0px 40px;
  position: relative;
  height: 685px;
}
.high-cer-container {
  position: relative;
  height: 715px;
  margin: 10px 25px;
}
.gr34-cer-container {
  margin: 10px;
  position: relative;
  height: 685px;
}

.gr34-end-header-container > p > span {
  display: inline-block;
  margin-left: 10px;
}
.gr34-end-header-container > p > span:first-of-type {
  font-size: 20px;
}
.gr34-cer-info {
  margin-bottom: 3px;
}
.gr34-cer-info > div {
  background: #fff;
  border: 0.5px solid #000;
  /* border: 4px outset; */
}

.high-cer-container .gr34-cer-info {
  background-color: #fff;
}

.gr34-cer-info > div {
  padding: 0px 5px;
}

.grading-system-table,
.gr34-content-table {
  border: 2px solid #000;
  width: 100%;
  margin-left: 3px;
  margin-bottom: 5px;
}
.grading-system-table td,
.grading-system-table th {
  border: 1px solid #000;
  text-align: center;
}
.grading-system-table td {
  text-align: center !important;
}

.grading-system-table > tbody > tr > td:first-of-type {
  text-align: start;
  margin-left: 3px;
}
.grading-system-table > tbody > tr > td:nth-child(1) {
  padding: 0px 3px;
}
.gr34-cer-container .grading-system-table > tbody > tr {
  line-height: 25px;
}

.middle-cer-container .grading-system-table > tbody > tr {
  line-height: 20px;
}
.gr34-content * {
  text-align: center;
  font-weight: 900;
}
.gr34-content {
  border: 2px solid #000;
  border-bottom: none;
}
.border-right-2 {
  border-right: 2px solid #000;
}

.border-left-2 {
  border-left: 2px solid #000;
}

.border-top-2 {
  border-top: 2px solid #000;
}
.border-bottom-2 {
  border-bottom: 2px solid #000;
}
.border-2 {
  border: 2px solid #000;
}
.GR34-footer-grding > div > div {
  padding: 0px 5px;
  text-align: center;
}
.GR34-footer-grding > div > div:nth-child(2) {
  border-left: 1px solid #000;
  height: 100%;
}
.GR34-footer-grding > div {
  height: 16.6%;
}
.bg-cer {
  background: #e0e0e0;
}

.spacialfont-middle * {
  font-size: 12px;
}

.high-cer-container .font-majalla {
  line-height: 20px;
}
.high-cer-container .gr34-cer-info {
  margin-bottom: 0px;
}

.cer-red {
  color: red;
}
.high-cer-container .hight-footer * {
  line-height: 17px;
}

.gr34-end-header-container > p > span:nth-of-type(1) {
  font-size: 12px;
}
.gr34-cer-info * {
  font-size: 14px !important;
}
.gr34-cer-info .font-majalla {
  font-size: 18px !important;
}
.cer-hight-g-s * {
  line-height: 17px;
}

.border-left-3 {
  border-left: 3px solid #000;
}

.cer-school-mang {
  font-size: 20px;
  font-weight: 900;
  padding-left: 50px;
}

.content-center * {
  text-align: center;
}
.gr34-footer-instruc * {
  font-size: 11px;
  font-weight: bold;
}

.middle-cer-container .grading-system-table > tbody > tr {
  line-height: 29px;
}

.middle-cer-container .GR34-footer-grding * {
  font-weight: 100;
}

.middle-cer-container .gr34-content * {
  font-weight: 100;
}

.allinside-fontweight-bold * {
  font-weight: bold !important;
}

.high-cer-container .h-grading-sys * {
  font-size: 13px;
}

.high-cer-container .gr34-cer-info > div {
  background: none;
  border: none;
}
.high-cer-container .gr34-end-header-container > img {
  width: 40px;
}
.signature-print > div > h5 {
  display: grid;
  grid-template-columns: auto 1fr;
  font-weight: bold;
  grid-gap: 10px;
  height: 30px !important;
}
.signature-print > div > h5 > p {
  margin: 0px;
}

.underline-signature {
  height: 1px;
  background-color: #000;
  align-self: end;
  transform: translateY(-10px);
}

.signature-print p {
  margin: 0px;
  font-weight: bold;
}
.signature-print {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 100px;
  margin-top: 20px;
}

.signature-print > div:last-of-type {
  align-self: end;
}
.assesmentpdfheader p {
  margin: auto !important;
}

.skills-table .ant-table-tbody .ant-table-cell {
  padding: 7px;
}

.grading-table .ant-table-tbody .ant-table-cell {
  padding: 10px;
}
.grading-table .ant-select-selection-item{
  text-align: center !important;
}

.gr12-cer-container * {
  background-color: white;
  color: black;
}

.gr12-cer-container .gr12-en-content {
  width: 95%;
  margin: auto;
  direction: 'ltr';
}
.gr12-cer-container .gr12-en-content * {
  font-size: 12px;
  font-family: 'Times New Roman D';
}
.kg-design-7.gr12-cer-container .gr12-en-content * {
  font-size: 13px;
}
.gr12-cer-container .gr12-en-content.gr12-design6 * {
  font-size: 14px;
}
.gr12-cer-container .gr12-en-content .subject-table *,
.gr12-cer-container .gr12-en-content .skill-table * {
  /* font-size: 14px; */
  font-family: 'Times New Roman D';
}

.gr12-cer-container .gr12-en-content tr {
  line-height: 15px;
}
.gr12-cer-container .gr12-en-content .subject-table tr,
.gr12-cer-container .gr12-en-content .skill-table tr {
  line-height: 15px;
}

.gr12-cer-container .gr12-ar-content {
  width: 95%;
  margin: auto;
  direction: 'ltr';
}

.gr12-ar-top-header {
  display: grid;
  grid-template-columns: 2fr 2fr 2fr;
  align-items: center;
}

.gr12-bg {
  background-color: var(--bg-gr12);
}

.gr1-2en-tables-con {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
}

.gr1-2ar-tables-con {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}
.gr1-2en-tables-con .en-table {
  width: 100%;
  border-collapse: collapse;
  border-bottom: 0px;
}
.gr1-2en-tables-con .key-table {
  width: 100%;
  border: 2px solid #000;
  border-collapse: collapse;
}
.gr1-2en-tables-con .subject-table {
  width: 100%;
  border: 1px solid #000;
  border-collapse: collapse;
}

.gr1-2en-tables-con .skill-table {
  width: 100%;
  border: 2px solid #000;
  border-collapse: collapse;
}
.gr1-2en-tables-con .en-table tr,
.gr1-2en-tables-con .en-table td,
.gr1-2en-tables-con .en-table th,
.gr1-2en-tables-con .key-table tr,
.gr1-2en-tables-con .key-table td,
.gr1-2en-tables-con .key-table th,
.gr1-2en-tables-con .subject-table tr,
.gr1-2en-tables-con .subject-table td,
.gr1-2en-tables-con .subject-table th,
.gr1-2en-tables-con .skill-table tr,
.gr1-2en-tables-con .skill-table td,
.gr1-2en-tables-con .skill-table th {
  border: 1px solid #000;
}

.gr1-2en-tables-con .key-table tr {
  line-height: 12px;
}
.gr1-2en-tables-con .en-table tr td,
.gr1-2en-tables-con .subject-table tr td,
.gr1-2en-tables-con .skill-table tr td {
  min-width: 30px;
}
.gr1-2en-tables-con .en-table tr td:nth-of-type(1),
.gr1-2en-tables-con .subject-table tr td:nth-of-type(1),
.gr1-2en-tables-con .skill-table tr td:nth-of-type(1) {
  width: auto;
}

.gr1-2en-tables-con .en-table tr td,
.gr1-2en-tables-con .subject-table tr td,
.gr1-2en-tables-con .skill-table tr td {
  text-align: center;
  font-weight: bold;
}

.gr1-2en-tables-con .en-table tr td:nth-child(1),
.gr1-2en-tables-con .subject-table tr td:nth-child(1),
.gr1-2en-tables-con .skill-table tr td:nth-child(1) {
  font-weight: normal;
  text-align: start;
  padding: 2px;
}
.gr1-2en-tables-con .en-table tr td:nth-child(1) {
  padding: 1px 0px 1px 2px;
}
.gr12-design6 .gr1-2en-tables-con .en-table tr td:nth-child(1),
.gr12-design6 .gr1-2en-tables-con .subject-table tr td:nth-child(1),
.gr12-design6 .gr1-2en-tables-con .skill-table tr td:nth-child(1) {
  padding: 3px;
}


.gr1-2en-tables-con .key-table tr td:nth-child(1) {
  text-align: center;
  padding: 0 5px;
}
.gr1-2en-tables-con .key-table tr td:nth-child(2) {
  padding: 1px 2px;
}
.gr12-cer-container .gr12-ar-content * {
  font-size: 16px;
  font-family: 'Sakkal Majalla' !important;
}
.kg-design-7.gr12-cer-container .gr12-ar-content * {
  font-size: 17px;
}
.gr12-cer-container .gr12-ar-content.gr12-design6 * {
  font-size: 18px;
}
.gr12-cer-container .gr12-ar-content tr {
  line-height: 20px;
}
.gr12-cer-container .gr12-ar-content.gr12-design6 table {
 height: 230px;
}
.gr12-cer-container .gr12-ar-content.gr12-design6 tr {
  line-height: 30px;
}
.gr12-cer-container .gr12-ar-content.gr12-design6.gr12-design8 tr {
  line-height: 23px;
}

.gr12-cer-container .gr12-ar-content .ar-table,
.gr12-cer-container .gr12-ar-content .subject-ar-table {
  width: 100%;
  border-collapse: collapse;
}
.gr12-cer-container .gr12-ar-content .ar-table td,
.gr12-cer-container .gr12-ar-content .ar-table tr,
.gr12-cer-container .gr12-ar-content .ar-table th,
.gr12-cer-container .gr12-ar-content .subject-ar-table td,
.gr12-cer-container .gr12-ar-content .subject-ar-table tr,
.gr12-cer-container .gr12-ar-content .subject-ar-table th {
  border: 1px solid #000;
}

.gr12-cer-container .gr12-ar-content .ar-table tr td,
.gr12-cer-container .gr12-ar-content .subject-ar-table tr td {
  min-width: 30px;
  text-align: center;
}
.gr12-cer-container .gr12-ar-content .ar-table tr td:nth-last-of-type(1),
.gr12-cer-container
  .gr12-ar-content
  .subject-ar-table
  tr
  td:nth-last-of-type(1) {
  width: 100%;
  text-align: end;
  padding-right: 5px;
}

.gr12-evaluation-Table {
  width: 95%;
  border: 2px solid #000;
  margin-left: auto;
  margin-top: 10px;
  background-color: var(--bg-gr12);
  border-collapse: collapse;
}
.gr12-evaluation-Table * {
  background-color: var(--bg-gr12);
}
.gr12-evaluation-Table tr td:nth-child(1) {
  width: auto;
  text-align: end;
  padding-right: 5px;
  border-right: 2px solid #000;
}
.gr12-evaluation-Table tr td:nth-child(2) {
  width: 30px;
  text-align: center;
}
.gr12-evaluation-Table tr td,
.gr12-evaluation-Table tr .gr12-evaluation-Table th {
  border: 1px solid #000;
}
.absent-table *{
  background-color: var(--bg-gr12);
}

.gr12-cer-container .gr12-ar-content .ar-tableheader {
  font-size: 18px;
  line-height: 20px;
}

.gr12-cer-check-icon {
  background: transparent;
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1.2);
}
.gr12-sign {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: auto;
  margin-top: 5px;
}
.gr12-sign p {
  font-weight: bold;
  font-size: 17px;
  border-top: 2px solid #000;
  text-align: center;
}
.gr12-sign img {
  width: 100px;
}
.gr12-sign img:nth-of-type(1) {
  padding-bottom: 5px;
  width: 120px;
}
.shared-sign {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 90%;
  bottom: 0px;
  left: 5%;
  align-items: end;
}
.shared-sign p {
  font-weight: bold;
  font-size: 17px;
  border-bottom: 2px solid #000;
}

.gr34-cer-container .cer-school-rss {
  width: 120px !important;
  margin-top: -10px;
}
.gr34-cer-container .shared-sign img:nth-of-type(1) {
  width: 125px;
  transform: scale(1.2);
}

.middle-cer-container .cer-school-rss {
  width: 120px !important;
  margin-top: -10px;
}

.middle-cer-container .shared-sign img:nth-of-type(1) {
  width: 120px;
  transform: scale(1.2);
}
.high-cer-container .cer-school-rss {
  width: 120px !important;
}
.high-cer-container .shared-sign img:nth-of-type(1) {
  width: 120px;
  transform: scale(1.2);
}

.cer-school-sign {
  margin-left: -30px;
}
.shared-sign article {
  text-align: start;
}
.shared-sign article:nth-of-type(2) {
  justify-self: center;
  margin-top: -5px;
}
.absent-top-header-cont * {
  font-size: 15px;
  color: #000;
}
.absent-top-header-cont {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: start;
}

.absent-top-header-cont article:nth-of-type(2) {
  justify-self: center;
  align-self: end;
  border: 2px solid #000;
  padding: 0px 30px;
  font-size: 25px;
  font-weight: bold;
}
.absent-top-header-cont article:nth-of-type(1) {
  display: grid;
  gap: 10px;
}
.absent-top-header-cont article:nth-of-type(3) {
  justify-self: end;
  align-self: end;
}
.absent-top-header-cont div {
  border: 1px solid #000;
  display: inline;
  width: fit-content;
  padding: 2px 5px;
}
.honor-tabel-info {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.honor-tabel-info div {
  padding: 0px;
  width: 100%;
}
.honor-tabel-info div p {
  margin: 0;
  padding: 0px 10px;
  text-align: center;
}
.alliner-bold * {
  font-weight: bold !important;
}
 /* this will target firefox only  */
@-moz-document url-prefix() {
  .alliner-bold>div:nth-child(1) * {
    font-weight: normal !important;
  }
}
.gr3-4-grading * {
  font-size: 14px;
}
.all-same-line-heighr * {
  line-height: 19px !important;
}

.ft11 {
  color: #000000;
  font-family: 'Sakkal Majalla' !important;
  font-size: 35px;
}
.ft12 {
  color: #000000;
  font-family: 'Sakkal Majalla' !important;
  font-size: 35px;
}
.ft13 {
  color: #000000;
  font-family: 'Sakkal Majalla' !important;
  font-size: 35px;
}
.ft14 {
  color: #000000;
  font-family: 'Sakkal Majalla' !important;
  font-size: 35px;
}
.ft15 {
  color: #000000;
  font-family: 'Sakkal Majalla' !important;
  font-size: 35px;
}
.ft16 {
  color: #000000;
  font-family: 'Sakkal Majalla' !important;
  font-size: 35px;
}
.ft17 {
  color: #000000;
  font-family: 'Sakkal Majalla' !important;
  font-size: 35px;
}
.ft10 {
  color: #000000;
  font-family: 'Sakkal Majalla' !important;
  font-size: 35px;
}

.reciep-dev p {
  margin: 0;
  padding: 0;
}

.font-ar {
  font-family: 'sakkal-majalla';
}

.hidden {
  visibility: hidden;
}
.cer-uploaded {
  color: #5ecd89;
}
.cer-notuploaded {
  color: red;
}
.hight-custmize-header-font * {
  font-size: 13px;
}
.subject-credit {
  font-size: 13.5px;
}
.emp-report-font-ar {
  font-family: 'sakkal-majalla';
  font-weight: bolder;
  color: #000;
  font-size: 25px;
}
.emp-report-sub-font-ar {
  font-family: 'sakkal-majalla';
  font-weight: 300;
  color: #000;
  font-size: 25px;
}
.sign-cer {
  margin-left: 0px;
}
.subject-en-cer:nth-of-type(1) {
  font-size: 13px;
}
.print-modules {
  font-family: 'sakkal-majalla';
}
.print-modules-headers {
  font-weight: bolder;
  color: #000;
  font-size: 20px;
  border: #000 2px solid;
  background-color: #fff;
  padding-inline: 8%;
  padding-block: 1%;
  /* margin-top:rem; */
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}
.print-modules-text {
  font-weight: bolder;
  color: #000;
  font-size: 20px;
}

.print-modules-sub-text {
  font-weight: bolder;
  color: #000;
  font-size: 13px;
}

.module-endorsement {
  border: #000 1px solid;
  width: 95%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-inline: 1%;
}
.table-one {
  color: #000;
  font-size: 20px;
  font-family: 'Sakkal Majalla' !important;
  text-align: center;
  width: 95%;
  margin: auto;
  border: 1px solid black;
}

.table-one,
.table-one td,
.table-one tr,
.table-one th {
  border: 1px solid #000;
  border-collapse: collapse;
  text-align: center;
  font-weight: bolder;
}
.table-one td:not(:first-child):not(:last-child) {
  width: 50%;
}

.table-two {
  color: #000;
  font-size: 20px;
  font-family: 'Sakkal Majalla' !important;
  text-align: center;
  width: 95%;
  margin: auto;
  border: 1px solid black;
}

.table-two,
.table-two td,
.table-two tr,
.table-two th {
  border: 1px solid #000;
  border-collapse: collapse;
  text-align: center;
  font-weight: bolder;
}

.table-two td:not(:first-child):not(:last-child) {
  width: 350px;
}

.signature-box {
  border: #000 1px solid;
  /* width: 20%; */
}

.sign-location {
  height: 10px;
}
.signature-box h1 {
  font-size: 20px;
  font-weight: bolder;
  color: #000;
  /* font-weight: bolder; */
  padding-inline: 1rem;
}

.date-box {
  width: 20%;
}
.date-box h1 {
  font-size: 20px;
  font-weight: bolder;
  color: #000;
  /* font-weight: bolder; */
  padding-inline: 1rem;
}

.table-three {
  color: #000;
  font-size: 20px;
  font-family: 'Sakkal Majalla' !important;
  text-align: center;
  width: 95%;
  margin: auto;
  border: 1px solid black;
}
.table-three,
.table-three td,
.table-three tr,
.table-three th {
  border: 1px solid #000;
  border-collapse: collapse;
  text-align: center;
  font-weight: bolder;
}

.table-financial {
  color: #000;
  font-size: 18px;
  font-family: 'Sakkal Majalla' !important;
  text-align: center;
  width: 95%;
  margin: auto;
  border: 1px solid black;
}

.table-financial,
.table-financial td,
.table-financial tr,
.table-financial th {
  border: 1px solid #000;
  border-collapse: collapse;
  text-align: center;
  font-weight: bolder;
}
.emp-name-cell {
  width: 30%;
}
.stalment-container * {
  color: #000;
  font-family: 'Sakkal Majalla' !important;
  direction: rtl;
}
.stalment-container {
  display: grid;
  grid-template-rows: auto auto auto 1fr;
  height: 1070px;
  margin: 20px auto;
  border: 2px solid #000;
  width: 95%;
  padding: 20px 0px;
}
.statement-top-header-cont article:nth-of-type(2) {
  justify-self: center;
  align-self: end;
  border-bottom: 2px solid #000;
  font-size: 25px;
  font-weight: bold;
}
.statement-top-header-cont article:nth-of-type(1) {
  display: grid;
  gap: 10px;
}
.statement-top-header-cont article:nth-of-type(3) {
  justify-self: end;
  align-self: end;
}
.statement-top-header-cont div {
  border: 1px solid #000;
  display: inline;
  width: fit-content;
  padding: 2px 5px;
}
.statement-top-header-cont * {
  font-size: 15px;
  color: #000;
}
.statement-top-header-cont {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: start;
}
.stalment-container table {
  border: 1px solid #000;
  border-collapse: collapse;
}
.statement-top-header-cont,
.stalment-container table {
  width: 95% !important;
  margin: 20px auto;
}
.stalment-container table td,
.stalment-container table tr {
  border: 1px solid #000;
}
.stalment-container table td {
  text-align: center;
  font-size: 24px;
  max-width: 30px;
}
.stalment-container table:nth-of-type(1) td:nth-of-type(2) {
  font-weight: bold;
}
.stalment-container section:nth-last-child(1) {
  align-self: end;
}
.stalment-container h1 {
  border-bottom: 2px solid #000;
  margin: auto;
  width: fit-content;
}
.fees-statment .feesPrint {
  border: 0px solid #000;
}

.transcript-title-text {
  font-weight: bolder;
  color: #000;
  font-size: 20px;
  text-decoration: underline;
}
.transcript-header-text {
  /* font-weight: bolder; */
  color: #000;
  font-size: 12px;
  line-height: 5px;
}
.transcript-body-text {
  font-weight: bold;
  color: #000;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
}
.transcript-body-text1 {
  font-weight: normal;
  color: #000;
  font-size: 16px;
  line-height: 0.9rem;
}
.transcript-body-text2 {
  /* font-weight:normal; */
  color: #000;
  font-size: 16px;
  line-height: 10px;
  padding: 1% 0;
}
.transcript-body-text3 {
  /* font-weight:normal; */
  color: #000;
  font-size: 11px;
  line-height: 13px;
  /* padding: 1% 0; */
}
.transcript-table {
  color: #000;
  font-size: 14px;
  font-family: 'Times New Roman' !important;
  text-align: center;
  width: 95%;
  margin: auto;
  border: 1px solid black;
}

.transcript-table,
.transcript-table td,
.transcript-table tr,
.transcript-table th {
  border: 1px solid #000;
  border-collapse: collapse;
  text-align: center;
  /* font-weight: bolder; */
  font-family: 'Times New Roman' !important;
}
.transcript-table td {
  line-height: 16px;
}

.grades-table {
  color: #000;
  font-size: 13px;
  font-family: 'Times New Roman' !important;
  text-align: center;
  width: 100%;
  margin: auto;
  border: 1px solid black;
}

.grades-table,
.grades-table td,
.grades-table tr,
.grades-table th {
  border: 1px solid #000;
  border-collapse: collapse;
  text-align: center;
  font-family: 'Times New Roman' !important;
  /* font-weight: bolder; */
}
.grades-table td:first-child {
  font-weight: bold;
}
.grades-table td {
  line-height: 15px;
}

.transcript-table td:first-child {
  text-align: start;
  padding-inline-start: 1rem;
  font-family: 'Times New Roman' !important;
}
.transcript-module {
  font-family: 'Times New Roman';
  background-color: white;
}

.transcript-arabic-font {
  font-family: 'Sakkal Majalla' !important;
  font-size: 15px;
}



.kg-bg {
  background-color: #e2efd9;
}

.kg-evaluation-Table {
  width: 95%;
  border: 2px solid #000;
  margin-left: auto;
  margin-top: 10px;
  /* background-color: var(--bg-gr12); */
  border-collapse: collapse;
}
.kg-evaluation-Table tr td:nth-child(1) {
  width: auto;
  text-align: end;
  padding-right: 5px;
  border-right: 2px solid #000;
}
.kg-evaluation-Table tr td:nth-child(2) {
  width: 30px;
  text-align: center;
}
.kg-evaluation-Table tr td,
.kg-evaluation-Table tr .kg-evaluation-Table th {
  border: 1px solid #000;
}
.perf-table tr:first-child {
  text-align: center;
}
.line-across {
  position: relative;
  background: linear-gradient(
    to top left,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) calc(50% - 0.8px),
    rgba(0, 0, 0, 1) 50%,
    rgba(0, 0, 0, 0) calc(50% + 0.8px),
    rgba(0, 0, 0, 0) 100%
  );
}
.logo-container-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.transfer {
  font-family: 'Sakkal Majalla' !important;
}

.transfer h1,
h2,
h3,
p {
  color: #000;
  /* font-weight: bold; */
}
.transfer input,
select {
  border: 0;
  color: #000;
  font-size: 22px;
  line-height: 1rem;
  font-weight: bold;
}
.transfer select {
  background-color: #fff;
}
.old-grade-table tfoot > tr > td,
.ant-table tfoot > tr > th,
.ant-table-tbody > tr > td,
.ant-table-thead > tr > th {
  padding: 5px !important;
}

.ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
  right: 55px !important;
}
.quill {
  direction: ltr;
}
.ar-quill-eidt .ql-editor {
  text-align: right;
  direction: rtl;
}
.suneditor-ltr {
  direction: ltr;
}
.suneditor-rtl {
  direction: rtl;
}
.sun-editor .se-container {
  direction: ltr;
}
.final-grades-listing {
  font-family: 'Sakkal Majalla' !important;
  border: #000 2px solid;
}
.final-grades-listing p {
  color: #000;
}

.final-grades-listing .black-font {
  color: #000;
}

.final-grades-listing .report-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* margin-bottom: 5%; */
  padding: 0 1rem;
}

.second-report-table {
  color: #000;
  font-weight: normal;
  font-size: 18px;
  font-family: 'Sakkal Majalla' !important;
  text-align: center;
  width: 95%;
  /* margin: auto; */
  /* border: 1px solid black; */
}

.second-report-table .student-name {
  width: 400px;
}
.second-report-table td {
  border: 1px solid black;
}

.report-table th,
td {
  font-weight: normal;
}

.report-table thead:first-child {
  border: none;
}
.medical-leave-modal {
  font-family: 'Sakkal Majalla' !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.medical-leave-table {
  color: #000;
  font-weight: normal;
  font-size: 18px;
  font-family: 'Sakkal Majalla' !important;
  text-align: center;
  width: 100%;
  border: 1px solid black;
}

.medical-leave-table td {
  border: 1px solid black;
  width: 20px;
}
.accept-registration-table {
  color: #000;
  font-weight: normal;
  font-size: 18px;
  font-family: 'Sakkal Majalla' !important;
  text-align: center;
  width: 100%;
  border: 1px solid black;
}

.accept-registration-table th {
  border: 1px solid black;
  width: 30%;
  background-color: #e5e5e5;
}

.accept-registration-table td {
  border: 1px solid black;
  height: 70px;
}
.medical-report-table {
  color: #000;
  font-weight: normal;
  font-size: 18px;
  font-family: 'Sakkal Majalla' !important;
  text-align: center;
  width: 100%;
  border: 1px solid black;
}

.medical-report-table th {
  border: 1px solid black;
  width: 30%;
}

.medical-report-table td {
  border: 1px solid black;
}
.medical-exam-table {
  background-color: #fff;
}

.notes-table {
  background-color: #fff;
}

.medicine-list-table {
  background-color: #d9e2f3;
}

.surgery-table {
  background-color: #bdd6ee;
}
.daily-list-table {
  color: #000;
  font-weight: normal;
  font-size: 18px;
  font-family: 'Sakkal Majalla' !important;
  text-align: center;
  width: 100%;
  border: 1px solid black;
  height: fit-content;
  margin-bottom: 10px;
}

.daily-list-table td {
  border: 1px solid black;
}
.notification-Table {
  width: 100%;
}

.notification-Table td {
  font-weight: bold;
  color: #000;
  text-align: center;
}

.notification-Table .notification-description {
  width: 20rem;
}

.yotube-video iframe {
  width: 100%;
}
.lp-contact-info {
  direction: rtl;
  border: 1px solid #000;
  padding: 30px 0px;
  margin: 30px;
  display: grid;
  grid-template-rows: auto 1fr auto;
}

.nextyear-transfare .ant-transfer-list {
  width: 100%;
  height: 100%;
}
.statistics-total td {
  font-weight: bold;
}

.bookRecord-table {
  color: #000;
  font-weight: normal;
  font-size: 18px;
  font-family: 'Sakkal Majalla' !important;
  text-align: center;
  width: 100%;
  border: 1px solid black;
  border-collapse: collapse;
}

.bookRecord-table td {
  font-weight: bold;
}
.bookRecord-table td {
  border: 1px solid black;
}

.w-4ch {
  width: 4ch;
}
.w-5ch {
  width: 5ch;
}

.header-print-final-grade header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 1rem;
}
.header-print-final-grade p {
  text-align: center;
  font-weight: 600;
  line-height: 33px;
  color: #000;
}

.medicalLeaveListTable {
  color: #000;
  font-weight: normal;
  font-size: 18px;
  font-family: 'Sakkal Majalla' !important;
  text-align: center;
  width: 100%;
  border: 1px solid black;
  border-collapse: collapse;
}

.medicalLeaveListTable td {
  font-weight: bold;
}

.medicalLeaveListTable td {
  border: 1px solid black;
}

.transfer_next_yaer .ant-transfer-list {
  width: 100vh;
  height: 100%;
}

.card-dashboard-icon {
  background: #edf1fd;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  align-items: center;
}
.text-black {
  color: #000 !important;
}
.small-lh-table tr {
  line-height: 24px;
}

.bordered-table {
  border: 1px solid #000;
  border-collapse: collapse;
  font-family: 'Sakkal Majalla' !important;
}
.bordered-table,
.bordered-table td, .bordered-table th {
  border: 1px solid #000;
}

.comprehensive-container {
  display: grid;
  grid-template-rows: auto auto 1fr auto;
  height: 1070px;
  color: #000;
  border: 1px solid #000;
  padding: 20px 0px;
  margin: 20px;
  direction: rtl;
}
.comprehensive-container h1 {
  color: #000;
  font-family: 'Sakkal Majalla';
}
.comprehensive-container table {
  width: 90%;
  height: 0px;
  font-size: 20px;
}
.comprehensive-container table tr td:nth-of-type(2) {
  font-weight: 900;
}
.gpa-container{
  padding: 3rem 16px;
}
.gpa-container * {
  color: #000;
  font-family: 'Sakkal Majalla';
  direction: rtl;
}
.gpa-container section,
.gpa-container table {
  font-size: 20px;
}
.gpa-container table {
  border: 1px solid #000;
  border-collapse: collapse;
  width: 100%;
}
.gpa-container table td {
  border: 1px solid #000;
  text-align: center;
}
.graduation-certification {
  margin:25px;
  padding: 25px;
  border: 1px solid #000;
  color: #000;
  text-align: center;
}
.graduation-certification * {
  color: #000 !important;
}
.andalus-font {
  font-family: 'Andalus';
}
.old-english-font {
  font-family: 'Old English Text MT';
}
.time-roman-d-font {
  font-family: 'Times New Roman D';
}
.fail-font {
  font-family: 'Sakkal Majalla' !important;
  font-size: 17px;
}
.directory-expand
  .ant-tree.ant-tree-directory
  .ant-tree-treenode-selected::before,
.ant-tree.ant-tree-directory .ant-tree-treenode-selected:hover::before {
  background: white;
}
.directory-expand
  .ant-tree.ant-tree-directory
  .ant-tree-treenode-selected
  span {
  color: #3e79f7c7;
}
.directory-expand
  .ant-tree.ant-tree-directory
  .ant-tree-treenode:hover::before {
  background: white;
}

.text-balck {
  color: #000;
}

.employee-application-print p {
  margin-bottom: 0px;
  line-height: 30px;
}
.employee-application-print *{
  color: #000;
}
.employee-application-print table {
  border: 1px solid #000;
  margin: 10px 0px;
}
.employee-application-print table td,
.employee-application-print table th {
  border: 1px solid #000;
}
.employee-application-print table th {
  width: 25%;
}
.employee-application-print table th {
  font-size: 12px;
}

tr.reports-rows > td {
  font-weight: bold;
}

.shared-print {
  direction: rtl;
  border: 2px solid;
  padding: 15px 30px 5px;
  margin: 10px;
  height: 1100px;
  font-family: 'sakkal-majalla';
  color: #000;
  font-size: 16px;
}
.shared-print table {
  width: 100%;
}
.shared-print td {
  line-height: 16px;
  text-align: center;
}

@media print {
  .remote-school-edit {
    border: none;
    padding: none;
  }
  input.date-without-icon[type='date']::-webkit-inner-spin-button,
  input.date-without-icon[type='date']::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }
}
input.date-without-icon[type='date']::-webkit-inner-spin-button,
input.date-without-icon[type='date']::-webkit-calendar-picker-indicator {
  margin-left: -20px;
}
.remote-school-edit {
  min-width: 100px;
}
.chart-grade .apexcharts-xaxis-texts-g text tspan {
  font-weight: bold;
}

.arabic-font {
  font-family: 'sakkal-majalla';
}
.account-print {
  border: 1px solid #000;
  height: 1080px;
  font-family: 'sakkal-majalla';
  font-size: 17px;
  box-sizing: border-box;
  overflow: hidden;
}
.account-print table,
.account-print tr,
.account-print td,
.account-print th {
  border: 2px solid #8ea8bf;
  border-collapse: collapse;
}
.account-print td,
.account-print th {
  padding: 5px;
  font-weight: bold;
}
.print-layout {
  display: grid;
  grid-template-rows: auto 1fr auto;
  color: #000;
}

.statistics-paid.ant-list-item {
  padding: 5px;
}

.account-print tr {
  line-height: 16px;
}
.account-print .table1,
.account-print .table1 tr,
.account-print .table1 td,
.account-print .table1 th {
  border-bottom: 0px;
}
.account-print .table1 div span {
  text-align: start;
}
.rmv .anticon-delete {
  display: none !important;
}



.transfare-students .ant-transfer-list{
  width: 100%;
  height: 100%;
}

.interview-model * {
  color: #000;
  font-family: 'Sakkal Majalla' ;
}
/* this will solve parentheses  */
.gr12-cer-container .gr12-ar-content .ar-table td:not(.text-center):nth-last-of-type(1),
.gr12-cer-container
  .gr12-ar-content
  .subject-ar-table td:nth-last-of-type(1):not(.text-center) {
  width: 100% !important;
  padding-right: 5px !important;
  direction: rtl !important;
  text-align: start !important;
}

/* .gr12-cer-container .gr12-ar-content .ar-table tbody tr:nth-of-type(1) td:nth-last-of-type(1),
.gr12-cer-container
  .gr12-ar-content
  .subject-ar-table td.ar-tableheader {
  text-align: center !important;
} */


html,
.scroll-container {
  scroll-behavior: smooth;
}
.print-text * {
  color: #000;
  padding: unset;
}
.bg-voucher{
  background-color: #9aa4aa;
}
.bg-voucher-cell{
  background-color: #deebf9;
}
.voucher-table {
  border-spacing:1px 0px;
  border-collapse:collapse;

}
.voucher-table td, .voucher-table th{
  border: 1px solid #000;
}
.nationality-table, .nationality-table td {
  border:2px solid #000;
  border-collapse: collapse;
}
.text-disabled {color:'#f7f7f8'}

/* diabled to be black */
input[disabled],
.ant-select-disabled .ant-select-selection-item,
input[disabled].ant-input-number-input,
.ant-picker-input>input[disabled]
{
  color: rgba(69, 85, 96) !important;
}
.firstPage p{
  font-size: 13.5px !important;
  line-height: 1.8 !important;
}
.custome-reports_style.font_big p{
  font-size: 32px;
}
.mark_statistics .apexcharts-text.apexcharts-yaxis-label:last-child{
  transform: translateX(-25px);
}
.mark_statistics .apexcharts-text.apexcharts-yaxis-label:last-child tspan{
  fill: red ;

}

.class-schedule-table tr.ant-table-row.ant-table-row-level-0 td{
  height: 100px !important;
}
.class-schedule-table .ant-table-tbody > tr.ant-table-row:hover > td{
  background: transparent ;
}

.class-schedule-table tr.ant-table-row.ant-table-row-level-0 td:not(:nth-of-type(1)):hover{
  background: rgb(250, 250, 250) ;
}

.student-mark-table .ant-table-content{
  overflow-x: auto ;
}

.minw-250px{
  min-width: 250px;
}

.mark-table thead tr th {
  min-width: min-content;
}
.mark-table{
  height: 100%;
}
.h-0 {
  height: 0px !important;
}
.w-0 {
  width: 0px !important;
}

.special-row, .special-row:hover > td{
  background-color: rgba(222,68,54,.1) !important;
}

.ant-modal-body{
  background: #fff;
}
.stationery-signature *{
  color: #000;
}

.columnResizer{
  background:0 0;
  max-width:0 !important;
  min-width:0 !important;
  width:0px !important;
  border-color:transparent}

.vr {
    display: inline-block;
    align-self: stretch;
    width: 1px;
    min-height: 1em;
    background-color: currentColor;
    opacity: .25;
}
.td-px-2 td {
  padding: 0rem 0.5rem;
}